<template>
  <div class="pt-1">
    <div class="profile-mis-favoritos">
      <div class="title-profile-mis-favoritos">
        <h4>Mis favoritos</h4>
        <p>{{ productWhislist.length }} productos</p>
      </div>
      <!-- <div class="share-favorite">
                <div>
                    <p class="mb-0"> <strong>COMPARTE TUS FAVORITOS</strong></p>
                    <p class="mb-0">Comparte esta URL con tus amigos y familiares y déjalos que te sorprendan</p>
                </div>
                <div>
                    <div class="shred-block">
                        <span>www.derek.co/favoritos/2572156</span>
                        <img class="ms-3 cursor-pointer" src="@/assets/icons/icon-copy.svg" alt="">
                    </div>
                </div>
            </div> -->
      <div v-if="productWhislist.length > 0 && token" class="products-favorite">
        <product-favorite
          v-for="(product, index) in productWhislist"
          :key="index"
          :product="product"
        />
      </div>
      <div v-else>
        <div class="container-bag-empty" style="height: 50vh">
          <div class="text-center">
            <h3>Aún no tienes productos favoritos</h3>
            <p class="fs-6">
              <!-- Inicia sesión o regístrate y podrás ver en este espacio los productos que agregaste como favoritos. <br> -->
              Recuerda que para añadir los productos a favoritos debes dar click
              al botón con un corazón.
            </p>
            <div class="my-3 d-flex justify-content-center">
              <svg
                id="Componente_1_9"
                style="border: 1px solid #f3f2f2"
                data-name="Componente 1 – 9"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
              >
                <rect
                  id="Rectángulo_2051"
                  data-name="Rectángulo 2051"
                  width="30"
                  height="30"
                  fill="#fff"
                ></rect>
                <path
                  id="Icon_feather-heart"
                  data-name="Icon feather-heart"
                  fill="none"
                  d="M20.781,6.039a5.262,5.262,0,0,0-7.444,0L12.323,7.054,11.309,6.039a5.264,5.264,0,1,0-7.444,7.444L4.879,14.5l7.444,7.444L19.767,14.5l1.014-1.014a5.262,5.262,0,0,0,0-7.444Z"
                  transform="translate(3.177 2.002)"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.6"
                ></path>
              </svg>
            </div>
            <!-- <div v-if="!token" @click="[$bvModal.show('modal-login'), $store.state.clickBtnFavorite = true]">
                            <button class="btn-primary px-4 fs-6">Iniciar sesión</button>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
    <footer-Returns />
    <modal-message
      :key="msgErrorAddProduct"
      :content="msgErrorAddProduct"
      :title="'Atención'"
    />
  </div>
</template>
<script>
import FooterReturns from '@/components/Returns/FooterReturns'
import ProductFavorite from '@/components/Profile/ProductFavorite'
import ModalMessage from '@/components/Templates/ModalMessage.vue'

import { mapState } from 'vuex'
export default {
  components: { FooterReturns, ProductFavorite, ModalMessage },
  data() {
    return {
      token: null,
    }
  },
  computed: {
    ...mapState('products', ['productWhislist']),
    ...mapState('cart', ['msgErrorAddProduct']),
  },
  created() {
    this.token = window.localStorage.getItem('tokenFavorite')
  },
}
</script>
