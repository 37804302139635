<template>
  <div class="item-product-add-to-cart">
    <img
      style="aspect-ratio: 8/9; width: 120px; margin-bottom: auto"
      :src="product.referencia.imagenes[0]"
      alt=""
    />
    <div class="content-product-add-to-cart">
      <div class="text-product-add-to-cart">
        <p class="mb-1">
          {{
            product.producto_titulo
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
        </p>
        <p class="data-text-product-add-to-cart text-start">
          SKU: {{ product.producto_sku }}<br />
          Talla: {{ product.referencia.talla.name }} <br />
          Color:
          {{
            product.referencia.color_nombre
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}
          <br />
          Cantidad: {{ product.count_products }}
        </p>
        <p
          v-if="product.dia_siniva == 1"
          class="price-movil-add-to-cart mx-auto my-2 d-block text-center d-md-none bold"
          style="line-height: .9"
        >
          ${{
            new Intl.NumberFormat('de-DE').format(
              product.producto_precio_siniva,
            )
          }}
          <br />
          <span style="font-size: 11px">*Precio sin IVA</span>
        </p>
        <p
          v-else
          class="price-movil-add-to-cart ms-auto mt-2 d-flex d-md-none bold"
        >
          ${{
            new Intl.NumberFormat('de-DE').format(
              product.precio * product.count_products,
            )
          }}
        </p>
      </div>
    </div>
    <div class="price-text-product-add-to-cart d-none d-md-flex">
      <p
        v-if="product.dia_siniva == 1"
        class="price-movil-add-to-cart ms-auto mt-2 text-center bold"
        style="line-height: .9"
      >
        ${{
          new Intl.NumberFormat('de-DE').format(product.producto_precio_siniva)
        }}
        <br />
        <span style="font-size: 11px">*Precio sin IVA</span>
      </p>
      <p v-else class="bold">
        ${{
          new Intl.NumberFormat('de-DE').format(
            product.precio * product.count_products,
          )
        }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
}
</script>
