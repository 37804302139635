<template>
  <b-modal
    id="modal-message"
    v-model="showModal"
    centered
    hide-header
    hide-footer
  >
    <img
      class="btn-close-modal-product-detail"
      src="@/assets/icons/delete-gray-icon.svg"
      alt="boton de cerrar"
      @click="showModal = false"
    />
    <div class="content-modal-info">
      <svg
        class="m-2 mt-3"
        style="min-width: 45px"
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 77 77"
      >
        <circle
          id="Elipse_2"
          data-name="Elipse 2"
          cx="38.5"
          cy="38.5"
          r="38.5"
          fill="#8dcfd7"
        />
        <g
          id="Icon_feather-check-circle"
          data-name="Icon feather-check-circle"
          transform="translate(19.2 19.2)"
        >
          <path
            id="Icon_material-error-outline"
            data-name="Icon material-error-outline"
            d="M18.647,25.6h3.477v3.477H18.647Zm0-13.909h3.477V22.125H18.647ZM20.369,3a17.386,17.386,0,1,0,17.4,17.386A17.377,17.377,0,0,0,20.369,3Zm.017,31.295A13.909,13.909,0,1,1,34.295,20.386,13.9,13.9,0,0,1,20.386,34.295Z"
            fill="#fff"
          />
        </g>
      </svg>
      <div class="content-data">
        <h5>{{ title }}</h5>
        <p v-html="content"></p>
        <button class="btn-primary px-4" @click="showModal = false">
          ¡Entendido!
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: 'Atención',
    },
    content: {
      type: String,
      required: false,
      default: 'parece que algo salió mal, inténtalo más tarde.',
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
}
</script>
