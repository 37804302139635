<template>
  <div>
    <b-modal
      id="modal-add-to-cart"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="content-add-to-cart">
        <div class="imgs-add-to-cart">
          <div class="w-100">
            <product-add-to-cart
              v-for="(product, index) in productGroup"
              :key="index"
              :product="product"
            />
          </div>
        </div>
        <div class="data-product-add-to-cart d-flex">
          <div v-if="dataCart" class="content-data-product-add-to-cart">
            <div class="w-100">
              <h3 class="title-add-to-cart">¡Añadido a la bolsa!</h3>
              <div class="data-price-add-to-cart">
                <p>
                  Subtotal:
                  <span
                    >${{
                      new Intl.NumberFormat('de-DE').format(
                        dataCart.carrito_subtotal,
                      )
                    }}</span
                  >
                </p>
                <p>
                  Descuentos:
                  <span
                    >${{
                      new Intl.NumberFormat('de-DE').format(
                        dataCart.carrito_descuento,
                      )
                    }}</span
                  >
                </p>
                <strong
                  ><p>
                    Total:
                    <span
                      >${{
                        new Intl.NumberFormat('de-DE').format(
                          dataCart.carrito_total,
                        )
                      }}</span
                    >
                  </p></strong
                >
              </div>
            </div>
            <div class="mt-auto d-flex flex-wrap mt-auto">
              <div class="w-100 order-2 order-md-1">
                <button
                  class="btn-continue-buy"
                  @click="$bvModal.hide('modal-add-to-cart')"
                >
                  Seguir comprando
                </button>
              </div>
              <router-link
                :to="{ name: 'MyBag' }"
                class="order-1 order-md-2 mb-3 mb-md-0 w-100"
              >
                <button
                  class="btn-primary fs-6 btn-add-cart-product-detail"
                  @click="$bvModal.hide('modal-add-to-cart')"
                >
                  <img src="@/assets/icons/shopping-bag.svg" alt="" /> Finalizar
                  compra
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <img
          class="btn-close-add-to-cart"
          src="@/assets/icons/delete-gray-icon.svg"
          alt="boton de cerrar"
          @click="$bvModal.hide('modal-add-to-cart')"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import ProductAddToCart from '@/components/ProductDetail/ProductAddToCart.vue'

import { mapState } from 'vuex'
export default {
  components: { ProductAddToCart },
  computed: {
    ...mapState('cart', ['dataCart', 'productsCart']),
    productGroup() {
      let products_group = []
      this.productsCart?.filter(product => {
        if (
          !products_group.find(
            element =>
              element.producto_sku == product.producto_sku &&
              element.referencia.color_id == product.referencia.color_id &&
              element.referencia.talla.id == product.referencia.talla.id &&
              product.producto_aplica_estrategia == 0 &&
              element.producto_aplica_estrategia != 1 &&
              element.descuento_aplica_estrategia ==
                product.descuento_aplica_estrategia,
          ) ||
          product.producto_aplica_estrategia == 1
        ) {
          let dataTemp = product
          dataTemp.count_products = 1
          products_group.push(product)
        } else {
          products_group = products_group.filter(element => {
            if (
              element.producto_sku == product.producto_sku &&
              element.referencia.color_id == product.referencia.color_id &&
              element.referencia.talla.id == product.referencia.talla.id &&
              element.producto_aplica_estrategia == 0 &&
              element.descuento_aplica_estrategia ==
                product.descuento_aplica_estrategia
            ) {
              element.count_products += 1
            }
            return element
          })
        }
      })
      return products_group
    },
  },
  methods: {},
}
</script>
<style scoped>
.container-cuotas-quac {
  margin-right: 0 !important;
  text-align: left;
}
</style>
