<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <profile-mis-favoritos class="container-profile-page" />
    </div>
    <add-to-cart />
  </div>
</template>
<script>
import BaseProfile from '@/components/Profile/BaseProfile'
import ProfileMisFavoritos from '@/components/Profile/ProfileMisFavoritos'
import AddToCart from '@/components/ProductDetail/AddToCart.vue'
import { sendFBQEvent } from '@/fbq'

export default {
  components: { BaseProfile, ProfileMisFavoritos, AddToCart },
  created() {
    document.title = 'Mis favoritos | DEREK Tienda Online'
    sendFBQEvent(this, 'PageView', {})
  },
}
</script>
