var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-product-bag flex-wrap mb-4"},[_c('div',{staticClass:"container-data-product-bag"},[_c('router-link',{attrs:{"to":{
        name: 'ProductDetail',
        params: { product: _vm.product.url.replace('product-', '') },
        query: { color: _vm.product.color },
      }}},[_c('img',{staticStyle:{"aspect-ratio":"8/9","max-width":"130px"},attrs:{"src":_vm.product.imagenes[0],"alt":""}})]),_c('div',{staticClass:"content-product-bag w-100",staticStyle:{"max-width":"320px"}},[_c('div',{staticClass:"text-product-bag",staticStyle:{"max-width":"280px","margin-right":"2rem"}},[_c('router-link',{attrs:{"to":{
            name: 'ProductDetail',
            params: { product: _vm.product.url.replace('product-', '') },
            query: { color: _vm.product.color },
          }}},[_c('p',[_vm._v(_vm._s(_vm.product.producto_titulo))])]),_c('p',{staticClass:"data-text-product-bag"},[_vm._v(" SKU: "+_vm._s(_vm.product.producto_sku)+" / Color: "+_vm._s(_vm.product.color)+" ")]),_c('p',{staticClass:"price-movil-bag d-md-none"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio))+" ")]),_c('p',{staticClass:"d-none d-md-flex bold"},[_vm._v("Elige una talla:")]),_c('div',{staticClass:"w-100 d-none my-2 d-md-flex position-relative"},[_vm._l((_vm.product.tallas),function(sizeProduct,index){return _c('div',{key:index,staticClass:"d-flex pe-3"},[_c('span',{staticClass:"sizes-product",class:sizeProduct.id == _vm.sizeSelected
                  ? 'size-selected'
                  : sizeProduct.stock == 0
                  ? 'size-no-aviable'
                  : '',style:(_vm.missingSize
                  ? `animation-name: missingSize; animation-duration: .5s;`
                  : ''),attrs:{"title":sizeProduct.stock == 0
                  ? 'Talla agotada'
                  : 'Talla: ' + sizeProduct.name},on:{"click":function($event){sizeProduct.stock > 0
                  ? [
                      (_vm.sizeSelected = sizeProduct.id),
                      (_vm.showMsgErrorSize = false),
                    ]
                  : ''}}},[_vm._v(_vm._s(sizeProduct.name))])])}),(_vm.showMsgErrorSize)?_c('span',{staticClass:"msg-error-size-favorite"},[_vm._v("Selecciona una talla")]):_vm._e()],2)],1),_c('div',{staticClass:"divider ms-2 d-none d-md-flex"})])],1),_c('div',{staticClass:"favorite-price-button price-text-product-bag d-flex justify-content-between"},[_c('p',{staticClass:"price-product-favorite"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio))+" ")]),(_vm.product.publicado == 1)?_c('div',{staticClass:"w-100 d-flex flex-wrap justify-content-around"},[_c('div',{staticClass:"d-flex flex-wrap mx-3 mb-0 d-md-none position-relative w-100"},[_c('p',{staticClass:"w-100 mb-2 mt-3 bold"},[_vm._v("Elige una talla:")]),_vm._l((_vm.product.tallas),function(sizeProduct,index){return _c('div',{key:index,staticClass:"d-flex pe-3"},[_c('span',{staticClass:"sizes-product",class:sizeProduct.id == _vm.sizeSelected
                ? 'size-selected'
                : sizeProduct.stock == 0
                ? 'size-no-aviable'
                : '',style:(_vm.missingSize
                ? `animation-name: missingSize; animation-duration: .5s;`
                : ''),attrs:{"title":sizeProduct.stock == 0
                ? 'Talla agotada'
                : 'Talla: ' + sizeProduct.name},on:{"click":function($event){sizeProduct.stock > 0
                ? [
                    (_vm.sizeSelected = sizeProduct.id),
                    (_vm.showMsgErrorSize = false),
                  ]
                : ''}}},[_vm._v(_vm._s(sizeProduct.name))])])}),(_vm.showMsgErrorSize)?_c('span',{staticClass:"msg-error-size-favorite"},[_vm._v("Selecciona una talla")]):_vm._e()],2),_c('div',{staticClass:"mx-3 w-100",staticStyle:{"max-width":"250px"}},[_c('button',{staticClass:"btn-primary w-100 my-3 btn-add-cart-product-detail",on:{"click":function($event){return _vm.addProduct()}}},[(!_vm.loading)?_c('div',[_c('img',{attrs:{"src":require("@/assets/icons/shopping-bag.svg"),"alt":"Agregar a la bolsa"}}),_vm._v(" Agregar a la bolsa ")]):_c('div',{staticClass:"d-flex justify-content-center px-4"},[_vm._v(" Cargando... "),_c('div',{staticClass:"loader"})])])])]):_c('div',{staticClass:"product-not-aviable-favorite mx-3 mt-3",staticStyle:{"max-width":"250px"}},[_c('p',{staticClass:"text-center"},[_vm._v(" Producto no disponible, descubre más opciones en "),_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":{
            name: 'TiendaCategory',
            params: { category: _vm.product.categoria.toLowerCase() },
          }}},[_c('strong',[_vm._v(_vm._s(_vm.product.subcategoria))])])],1)])]),_c('div',{staticClass:"delete-product-bag",on:{"click":function($event){return _vm.deleteProduct()}}},[(!_vm.loadingDelete)?_c('img',{attrs:{"src":require("@/assets/icons/delete-gray-icon.svg"),"alt":"boton cerrar"}}):_c('div',{staticClass:"loader loader-delete"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }